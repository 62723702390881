@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root + div.jvectormap-tip,
div.jvectormap-tip:nth-child(5) {
  display: none !important;
}

.font-inter {
  font-family: "Inter", cursive;
}
.leaflet-container {
  width: 100%;
  height: 100vh;
  z-index: 1;
}

.map-marker path:hover {
  stroke: #0e97fb;
}
.map-marker path {
  stroke: #868686;
  stroke-width: 0.5px;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.jvectormap-marker {
  width: 28px;
  height: 28px;
  padding-right: 15px;
}

tr {
  height: 70px;
}
